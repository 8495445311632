/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://eleeyww014.execute-api.eu-west-3.amazonaws.com/prd",
            "region": "eu-west-3"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://j3q4mdsoa5bjxlwq37nto3g37a.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-3:a00cef04-413c-410d-9d3e-3a804f893127",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_KLlbFO9Qa",
    "aws_user_pools_web_client_id": "2g5003p276g87bivh7q513mn8s",
    "oauth": {
        "domain": "tennisbookator841cb904-841cb904-prd.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://reservations.tcgrandson.ch",
        //"redirectSignIn": "http://localhost:8080",
        "redirectSignOut": "https://reservations.tcgrandson.ch",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
