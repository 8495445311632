<template>
  <Authenticator :social-providers="['google']">
    <template v-slot:header>
      <div style="padding: var(--amplify-space-large); text-align: center">
        <img
          class="amplify-image"
          alt="logo"
          src="https://www.tcgrandson.ch/assets/img/tcg/Logo_TCG.jpg"
        />
        <!--<h1>Nous rencontrons actuellement des problèmes pour le login.<br>Nous travaillons sur le problème</h1>-->
      </div>
    </template>
    <template v-slot="{ user, signOut }">
      <NavBar @signoutEvent="signOut" :user="user" />
      <router-view :user="user" />
    </template>
  </Authenticator>

</template>

<script setup>
import NavBar from '@/components/NavBar.vue';
import { Authenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    'Family Name': "Nom",
    'Given Name': 'Prénom',
    "Sign Up with Google": "S'enregistrer avec Google",
  },
});



</script> 



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.amplify-button[data-variation='primary'] {
  background-color: darkgoldenrod;
}

.amplify-button[data-variation="link"] {
  color: darkgoldenrod;
}

.amplify-tabs-item {
  color: darkgoldenrod;
}

.amplify-tabs-item[data-state="active"] {
  border-top-color: darkgoldenrod;
  color: darkgoldenrod;
}

.amplify-divider--label::after {
  content: "ou";
}

</style>
