import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/index.css'
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
Amplify.configure(awsExports);

const app = createApp(App).use(router).mount('#app');
//createApp(App).use(router).mount('#app');
app.component('DatePicker', DatePicker);
